import Washi from 'washi';
import Fixed from './fixed';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import * as focusTrap from 'focus-trap';

const Modal = Washi.$.extend({

  handleWindowKeydown: function (e) {
    if (e.keyCode == 27) {
      e.preventDefault()
      this.hide(e)
    }
  },

  hide: function (e, el = this.el, hideFocusEl = this.hideFocusEl, scrollEl = this.scrollEl) {
    let youtubeEl = el.querySelector('[data-component="youtube"]')
    let youtubeIframe,
      youtubeSrc

    if (youtubeEl) {
      youtubeIframe = youtubeEl.querySelector('iframe')
      youtubeSrc = youtubeIframe.getAttribute('src')
    }

    e.preventDefault();
    el.classList.remove('-in');
    el.classList.add('-out');

    if (this.trap) {
      this.trap.deactivate()
    }

    setTimeout(function () {
      this.stopVideo(youtubeIframe, youtubeSrc)
      el.classList.remove('-active')

      hideFocusEl && hideFocusEl.focus()
    }.bind(this), 400);

    // allow override for body-scroll-lock target #5818
    // set on interaction modals
    const scrollTarget = scrollEl ? scrollEl : el;
    enableBodyScroll(scrollTarget);
  },

  isDebug: function () {
    const loc = window.location;
    return loc.search.indexOf('force_modal=true') !== -1
      && (loc.hostname.indexOf('.test') !== 1 || loc.hostname.indexOf('vigetx.com') !== -1)
  },

  isExcluded: function () {
    const excluded = this.excluded || []; // optionally provide excluded pathnames, i.e. '/about-us'
    if (excluded.length === 0) return false;

    const path = window.location.pathname;
    return excluded.some(e => path.indexOf(e) !== -1)
  },

  tryShow: function (isAllowed = () => true, onSuccess = () => { }) {
    const shouldShow = (!this.isExcluded() && isAllowed()) || this.isDebug();

    if (shouldShow) {
      this.show();
      onSuccess();
    }
  },

  show(modalEl = this.el, shouldDisableBodyScroll = true, showFocusEl, scrollEl = this.scrollEl) {
    this.trap = this.trap || focusTrap.createFocusTrap(modalEl)

    modalEl.classList.add('-active');
    this.trap.activate()

    setTimeout(function () {
      if (!showFocusEl) {
        modalEl.classList.add('-in');
        showFocusEl = modalEl.querySelector('button');
      }

      showFocusEl && showFocusEl.focus()
    }.bind(this), 50);

    // allow override for body-scroll-lock target #5818
    // set on interaction modals
    const scrollTarget = scrollEl ? scrollEl : modalEl;

    if (shouldDisableBodyScroll) {
      disableBodyScroll(scrollTarget);
    }

    window.addEventListener('keydown', this.handleWindowKeydown.bind(this), false)
  },

  stopVideo(iframe, src) {
    // hack to stop video without YouTubeAPI: refresh the src
    if (iframe && src) {
      iframe.setAttribute('src', src)
    }
  }

}, Fixed);

export default Modal;
